function HelloUser() {
  return (
    <div className="hello-user-body">
      <div className="hello-user-container">
        <p className="login-hello-title">Hello user</p>
      </div>
    </div>
  );
}

export default HelloUser;
